var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page vh-100 vw-100",style:(_vm.background)},[_c('div',{staticClass:"container flex-center h-100 w-100"},[_c('div',{staticClass:"w--90 bg-secondary-o80 bd-blur-5 rounded-2"},[_c('ValidationObserver',{staticClass:"flex-column p-10 text-light",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"logo flex-center"},[_c('div',{staticClass:"flex-center h--40 rounded-pill mb-7"},[_c('img',{staticClass:"img-contain",attrs:{"src":_vm.logo}})])]),_c('div',{staticClass:"user-id py-2 mb-3"},[_c('label',{staticClass:"mb-1"},[_vm._v(" Tài khoản ")]),_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"rules":{ required: true, no_script_tag: true },"name":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control bg-light position-relative z-1 fs-12 h--8",class:{ 'border-danger': errors[0] },attrs:{"type":"text","name":"username","autocapitalize":"none"},domProps:{"value":(_vm.username)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.login)},"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"password py-2 mb-6"},[_c('label',{staticClass:"mb-1"},[_vm._v(" Mật khẩu ")]),_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                min: 8,
                no_script_tag: true
              },"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control bg-light position-relative z-1 fs-12 h--8",class:{ 'border-danger': errors[0] },attrs:{"type":"password","name":"password","autocapitalize":"none"},domProps:{"value":(_vm.password)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.login)},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"login-btn flex-center flex-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.blockui},on:{"click":function($event){return handleSubmit(_vm.login)}}},[_vm._v(" Đăng nhập ")])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }