



























































































import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/services/AuthService";

//vuex
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import Auth from "@/store/modules/Auth";
import User from "@/store/modules/User";
const AuthModule = getModule(Auth, store);
const UserModule = getModule(User, store);

@Component
export default class LoginPage extends Vue {
  private background: any = {
    backgroundImage: "url(" + require("@/assets/images/wall/"+ (Math.floor(Math.random()*100)+1)+".jpg") + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  };
  private logo: string = require("@/assets/images/lucas-logos/lucas-logos_transparent.png");
  private username: string = "";
  private password: string = "";
  private blockui: boolean = false;

  async login() {
    if (this.blockui) return;
    this.blockui = true;
    const md5 = require("md5");
    AuthService.login(this.username, md5(this.password))
      .then(res => {
        if (res.status === 200) {
          //set token
          const token = res.data.jwtToken;
          AuthModule.SET_NEW_TOKEN(token);

          //set user info
          UserModule.SET_NEW_USER_INFO({
            username: res.data.username,
            fullname: res.data.fullname,
            role: res.data.role
          });
          this.$router.push({ name: "dashboard" });
        }
      })
      .catch(err => {
        this.$swal.fire({
          icon: "error",
          title: "Tên đăng nhập hoặc mật khẩu không đúng",
          showConfirmButton: false,
          timer: 2000
        });
      })
      .finally(() => {
        this.blockui = false;
      });
  }
}
